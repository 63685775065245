// 人员模块-调离
import {
  USER
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 待离职
export async function waitQuitList(params) {
  return request(`${USER}/transfer/leave/waitLeaveList`, METHOD.GET, params)
}

// 已离职列表
export async function alreadyQuitList(params) {
  return request(`${USER}/staffLeaveInfo/alreadyLeaveList`, METHOD.GET, params)
}

// 添加待离职
export async function addQuitEntry(params) {
  return request(`${USER}/transfer/leave/addWaitLeave`, METHOD.POST, params)
}

// 获取可离职人员
export async function getCanLeaveStaff(params) {
  return request(`${USER}/staff/getCanLeaveStaff`, METHOD.GET, params)
}

// 获取离职详情
export async function getLeaveDetail(params) {
  return request(`${USER}/transfer/leave/getLeaveDetail`, METHOD.GET, params)
}

// 获取已离职离职详情
export async function getLeavedDetail(params) {
  return request(`${USER}/staffLeaveInfo/getLeaveDetail`, METHOD.GET, params)
}

// 离职删除
export async function deleteQuitEntry(params) {
  return request(`${USER}/transfer/leave/deleteAlreadyLeave`, METHOD.POST, params)
}

// 修改计划离职时间
export async function updatePlanLeaveDateWaitLeave(params) {
  return request(`${USER}/transfer/leave/updatePlanLeaveDateWaitLeave`, METHOD.POST, params)
}

// 待离职导出
export async function waitLeaveListExport(params) {
  return request(`${USER + '/transfer/leave/waitLeaveListExport'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 已离职导出
export async function alreadyLeaveListExport(params) {
  return request(`${USER}/staffLeaveInfo/alreadyLeaveListExport`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}
